import "styles/pages/order.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import ConfirmationContent from "components/ConfirmationContent/ConfirmationContent"

const Confirmation = () => {
  const title = "Zamówienie złożone"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="order-confirmation">
        <div className="container-fluid">
          <PageHeader title={title} />

          <ConfirmationContent />
        </div>
      </section>
    </Layout>
  )
}

export default Confirmation
